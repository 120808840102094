import React from "react";
import ContactSection from "../../Components/ContactSection/ContactSection";

const Contact = () => {
  return (
    <div>
      <div>
        <ContactSection />
      </div>
    </div>
  );
};

export default Contact;
