import React from "react";
import AboutSection from "../../Components/AboutSection/AboutSection";

const About = () => {
  return (
    <div>
      <div>
        <AboutSection />
      </div>
    </div>
  );
};

export default About;
