import React from "react";
import PartnersSection from "../../Components/PartnersSection/PartnersSection";

const Partners = () => {
  return (
    <div>
      <div>
        <PartnersSection />
      </div>
    </div>
  );
};

export default Partners;
