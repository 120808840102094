import React from "react";
import TeamSection from "../../Components/TeamSection/TeamSection";

const Team = () => {
  return (
    <div>
      <div>
        <TeamSection />
      </div>
    </div>
  );
};

export default Team;
