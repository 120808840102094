import React from "react";
import HomeSection from "../../Components/HomeSection/HomeSection";

const Home = () => {
  return (
    <div>
      <HomeSection />
    </div>
  );
};

export default Home;
